@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body {
  font-family: "Inter", "ui-sans-serif", "system-ui", "-apple-system",
    "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial",
    '"Noto Sans"', "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"',
    '"Segoe UI Symbol"', '"Noto Color Emoji"', sans-serif;
  font-variation-settings:
    "ital" 0,
    "opsz" 6,
    "wght" 200;
  font-optical-sizing: auto;
  scroll-behavior: smooth; /* Firefox */
  scrollbar-gutter: stable; /* Firefox */
  scrollbar-width: none; /* Firefox */
}
auto *::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari & Opera */
.debug-outline {
  outline: 1px solid red;
}

@layer utilities {
  .font-thin {
    font-variation-settings:
      "ital" 0,
      "wght" 100;
  }
  .font-extralight {
    font-variation-settings:
      "ital" 0,
      "wght" 200;
  }
  .font-light {
    font-variation-settings:
      "ital" 0,
      "wght" 300;
  }
  .font-normal {
    font-variation-settings:
      "ital" 0,
      "wght" 400;
  }
  .font-medium {
    font-variation-settings:
      "ital" 0,
      "wght" 500;
  }
  .font-semibold {
    font-variation-settings:
      "ital" 0,
      "wght" 600;
  }
  .font-bold {
    font-variation-settings:
      "ital" 0,
      "wght" 700;
  }
  .font-extrabold {
    font-variation-settings:
      "ital" 0,
      "wght" 800;
  }
  .font-black {
    font-variation-settings:
      "ital" 0,
      "wght" 900;
  }
  .text-xs {
    font-variation-settings: "opsz" 12;
  }
  .text-sm {
    font-variation-settings: "opsz" 14;
  }
  .text-base {
    font-variation-settings: "opsz" 16;
  }
  .text-lg {
    font-variation-settings: "opsz" 18;
  }
  .text-xl {
    font-variation-settings: "opsz" 20;
  }
  .text-2xl {
    font-variation-settings: "opsz" 24;
  }
  .text-3xl {
    font-variation-settings: "opsz" 30;
  }
  .text-4xl {
    font-variation-settings: "opsz" 36;
  }
  .text-5xl {
    font-variation-settings: "opsz" 48;
  }
  .text-6xl {
    font-variation-settings: "opsz" 60;
  }
  .text-7xl {
    font-variation-settings: "opsz" 72;
  }
  .text-8xl {
    font-variation-settings: "opsz" 96;
  }
  .text-9xl {
    font-variation-settings: "opsz" 128;
  }
  .pause {
    animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    -webkit-animation-play-state: paused;
  }
  .custom-scrollbar {
    scrollbar-width: none;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 0px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 2px solid transparent;
    background-clip: content-box;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }
}

@layer base {
  :root {
    --color-success: 34, 197, 94; /* green */
    --color-success-content: 0, 0, 0; /* black */
    --color-warning: 251, 191, 36; /* orange */
    --color-warning-content: 0, 0, 0; /* black */
    --color-error: 239, 68, 68; /* red */
    --color-error-content: 0, 0, 0; /* black */
    --color-base-100: 255, 255, 255; /* white */
    --color-base-200: 245, 245, 245; /* lightgray */
    --color-base-300: 229, 229, 229; /* darkgray */
    --color-base-content: 0, 0, 0; /* black */
    --color-grapefruit-50: 255, 244, 240; /* brand */
    --color-grapefruit-100: 255, 230, 221;
    --color-grapefruit-200: 255, 205, 187;
    --color-grapefruit-300: 252, 169, 140;
    --color-grapefruit-400: 250, 125, 87;
    --color-grapefruit-500: 245, 87, 45;
    --color-grapefruit-600: 227, 60, 25;
    --color-grapefruit-700: 191, 44, 19;
    --color-grapefruit-800: 153, 39, 23;
    --color-grapefruit-900: 128, 36, 24;
  }

  .light {
    --color-success: 34, 197, 94; /* green */
    --color-success-content: 0, 0, 0; /* black */
    --color-warning: 251, 191, 36; /* orange */
    --color-warning-content: 0, 0, 0; /* black */
    --color-error: 239, 68, 68; /* red */
    --color-error-content: 0, 0, 0; /* black */
    --color-base-100: 255, 255, 255; /* white */
    --color-base-200: 245, 245, 244; /* lightgray */
    --color-base-300: 231, 229, 228; /* darkgray */
    --color-placeholder: 87, 83, 78; /* gray */
    --color-base-content: 0, 0, 0; /* black */
  }

  .dark {
    --color-success: 34, 197, 94; /* green */
    --color-success-content: 0, 0, 0; /* black */
    --color-warning: 251, 191, 36; /* orange */
    --color-warning-content: 0, 0, 0; /* black */
    --color-error: 239, 68, 68; /* red */
    --color-error-content: 0, 0, 0; /* black */
    --color-base-100: 28, 25, 23; /* black */
    --color-base-200: 41, 37, 36; /* gray */
    --color-base-300: 68, 64, 60; /* darkgray */
    --color-placeholder: 168, 162, 158; /* gray */
    --color-base-content: 255, 255, 255; /* white */
  }
}
